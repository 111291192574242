<template>
  <div class="flex xs12">
    <local-table
      class="table-hover table-striped"
      crud-links="trainings"
      controller="DcpiTrainings"
      :columns="trainingsFields"
      :data="trainings"
      :loading="loading"
      :editable="true"
      :top-options="[]"
      :action-options="['view', 'download']"
      @view-item="router.push({ name: 'trainingsView', params: { id: $event.id } })"
      @download-item="tryDownload"
    />
  </div>
</template>
<script type="text/javascript" src="http://ajax.googleapis.com/ajax/libs/jquery/1.8.3/jquery.min.js"></script>
<script src="html2pdf.bundle.min.js"></script>
<script>
import { mapGetters } from 'vuex'
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')

export default {
  name: 'trainings-table',
  components: {
    LocalTable,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    trainings: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data () {
    return {
      participant: {},
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters(['currentLocale']),
    notValid () {
      return this.trainings.length === 0
    },
    trainingsFields () {
      return [
        {
          name: 'location',
          title: this.$t('tables.headings.location'),
        },
        {
          name: 'end_date',
          title: this.$t('tables.headings.end_date'),
        },
        {
          name: 'organizer.name',
          title: this.$t('tables.headings.organizer'),
        },
        {
          name: 'training_type',
          title: this.$t('tables.headings.training_type'),
          callback: this.typeLabel,
        },
        {
          name: '__slot:actions',
          visible: this.trainings.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  watch: {
    trainings (val) {
      if (val.length > 0) {
        const trainings = this.fixData(val)
        this.trainingsList = trainings.slice(0)
      }
    },
  },
  methods: {
    fixData (data) {
      // let noDef = this.$t('tables.undefined')
      for (const d of data) {
        if (!d.organizer) {
          d.organizer = {
            name: '',
          }
        }
      }
      return data
    },
    typeLabel (type) {
      let translate = this.$t('tables.undefined')

      switch (this.currentLocale) {
        case 'esp': translate = type.name; break
        case 'por': translate = type.name_pt; break
        case 'eng': translate = type.name_en; break
      }

      return translate
    },
    async tryDownload (item) {
      if (!item._joinData.approve) {
        const result = await this.$swal({
          icon: 'warning',
          text: this.$t('notifications.resend.success.did.not.pass.the.training'),
          showCancelButton: false,
          confirmButtonText: this.$t('layout.buttons.accept'),
        })
        return
      }
      const download = {
        icon: 'info',
        title: this.$t('notifications.resend.sending'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          const resendRoute = `academy/certificate/${item.id}`
          let response = false
          try {
            response = await this.$http.post(resendRoute, { user: this.user.id }, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant resend file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'certificate.pdf')
          document.body.appendChild(link)
          link.click()

          this.showToast(this.$t('notifications.alerts.success'), {
            icon: 'fa-check',
            position: 'top-right',
          })
          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.resend.failed'),
        confirmButtonText: this.$t('layout.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }
      this.$swal.queue([download])
    },
  },
}
</script>
